import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import { Button, ButtonPrimary, ButtonSecondary, ButtonTertiary, IconButton } from '../src/index';
import { IconLanguage, IconArrowDropDown } from '@happeouikit/icons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "buttons"
    }}>{`Buttons`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h3 {...{
      "id": "default"
    }}>{`Default`}</h3>
    <Playground __position={1} __code={'<div style={{ display: \'flex\' }}>\n  <ButtonPrimary text=\"Primary Button\" />\n  <ButtonSecondary text=\"Secondary Button\"></ButtonSecondary>\n  <ButtonTertiary text=\"Tertiary Button\" />\n</div>\n<div\n  style={{\n    display: \'flex\',\n    flexDirection: \'row\',\n    alignItems: \'center\',\n    flexWrap: \'wrap\',\n  }}\n>\n  <ButtonPrimary text=\"Primary with Icon\" icon={IconLanguage} />\n  <ButtonPrimary\n    text=\"Primary with Icon\"\n    icon={IconLanguage}\n    iconPosition=\"right\"\n  />\n  <ButtonPrimary\n    text=\"Channels\"\n    icon={IconLanguage}\n    type=\"dark\"\n    iconPosition=\"top\"\n  />\n  <ButtonPrimary text=\"Pages\" icon={IconLanguage} iconPosition=\"bottom\" />\n  <ButtonSecondary\n    text=\"Secondary with Icon\"\n    icon={IconLanguage}\n  ></ButtonSecondary>\n  <ButtonTertiary text=\"Tertiary with Icon\" icon={IconLanguage} />\n</div>\n<div style={{ display: \'flex\', marginTop: \'20px\', alignItems: \'center\' }}>\n  <ButtonPrimary text=\"Small Button\" size=\"small\" />\n  <ButtonPrimary text=\"Small Button\" size=\"small\" icon={IconLanguage} />\n  <ButtonPrimary text=\"Large Button\" size=\"large\" />\n  <ButtonPrimary\n    text=\"Large Button\"\n    size=\"large\"\n    icon={IconLanguage}\n    data-tracker=\"Large Language Button\"\n  />\n</div>\n<div style={{ display: \'flex\', marginTop: \'20px\', alignItems: \'center\' }}>\n  <ButtonPrimary\n    text=\"Dark primary button\"\n    icon={IconArrowDropDown}\n    type=\"dark\"\n    iconPosition=\"right\"\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Button,
      ButtonPrimary,
      ButtonSecondary,
      ButtonTertiary,
      IconButton,
      IconLanguage,
      IconArrowDropDown,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        display: 'flex'
      }}>
        <ButtonPrimary text='Primary Button' mdxType="ButtonPrimary" />
        <ButtonSecondary text='Secondary Button' mdxType="ButtonSecondary"></ButtonSecondary>
        <ButtonTertiary text='Tertiary Button' mdxType="ButtonTertiary" />
     </div>
    <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap'
      }}>
        <ButtonPrimary text='Primary with Icon' icon={IconLanguage} mdxType="ButtonPrimary" />
        <ButtonPrimary text='Primary with Icon' icon={IconLanguage} iconPosition="right" mdxType="ButtonPrimary" />
        <ButtonPrimary text='Channels' icon={IconLanguage} type="dark" iconPosition="top" mdxType="ButtonPrimary" />
        <ButtonPrimary text='Pages' icon={IconLanguage} iconPosition="bottom" mdxType="ButtonPrimary" />
        <ButtonSecondary text='Secondary with Icon' icon={IconLanguage} mdxType="ButtonSecondary"></ButtonSecondary>
        <ButtonTertiary text='Tertiary with Icon' icon={IconLanguage} mdxType="ButtonTertiary" />
    </div>
    <div style={{
        display: 'flex',
        marginTop: '20px',
        alignItems: 'center'
      }}>
        <ButtonPrimary text='Small Button' size='small' mdxType="ButtonPrimary" />
        <ButtonPrimary text='Small Button' size='small' icon={IconLanguage} mdxType="ButtonPrimary" />
        <ButtonPrimary text='Large Button' size='large' mdxType="ButtonPrimary" />
        <ButtonPrimary text='Large Button' size='large' icon={IconLanguage} data-tracker="Large Language Button" mdxType="ButtonPrimary" />
    </div>
    <div style={{
        display: 'flex',
        marginTop: '20px',
        alignItems: 'center'
      }}>
        <ButtonPrimary text='Dark primary button' icon={IconArrowDropDown} type="dark" iconPosition="right" mdxType="ButtonPrimary" />
    </div>
    </Playground>
    <h3 {...{
      "id": "success"
    }}>{`Success`}</h3>
    <Playground __position={2} __code={'<ButtonPrimary text=\"Primary Button\" type=\"success\" />\n<ButtonSecondary text=\"Secondary Button\" type=\"success\"></ButtonSecondary>\n<ButtonSecondary\n  text=\"Secondary Button\"\n  type=\"success\"\n  icon={IconLanguage}\n></ButtonSecondary>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Button,
      ButtonPrimary,
      ButtonSecondary,
      ButtonTertiary,
      IconButton,
      IconLanguage,
      IconArrowDropDown,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <ButtonPrimary text='Primary Button' type='success' mdxType="ButtonPrimary" />
    <ButtonSecondary text='Secondary Button' type='success' mdxType="ButtonSecondary"></ButtonSecondary>
    <ButtonSecondary text='Secondary Button' type='success' icon={IconLanguage} mdxType="ButtonSecondary"></ButtonSecondary>
    </Playground>
    <h3 {...{
      "id": "dark"
    }}>{`Dark`}</h3>
    <Playground __position={3} __code={'<ButtonPrimary\n  type=\"dark\"\n  icon={IconLanguage}\n  text=\"Primary Dark button with icon\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Button,
      ButtonPrimary,
      ButtonSecondary,
      ButtonTertiary,
      IconButton,
      IconLanguage,
      IconArrowDropDown,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <ButtonPrimary type="dark" icon={IconLanguage} text="Primary Dark button with icon" mdxType="ButtonPrimary" />
    </Playground>
    <h3 {...{
      "id": "disabled"
    }}>{`Disabled`}</h3>
    <Playground __position={4} __code={'<ButtonPrimary text=\"Primary Button\" disabled />\n<ButtonSecondary text=\"Secondary Button\" disabled></ButtonSecondary>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Button,
      ButtonPrimary,
      ButtonSecondary,
      ButtonTertiary,
      IconButton,
      IconLanguage,
      IconArrowDropDown,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <ButtonPrimary text='Primary Button' disabled mdxType="ButtonPrimary" />
    <ButtonSecondary text='Secondary Button' disabled mdxType="ButtonSecondary"></ButtonSecondary>
    </Playground>
    <h3 {...{
      "id": "iconbutton"
    }}>{`IconButton`}</h3>
    <Playground __position={5} __code={'<small>Primary</small> <br />\n<IconButton icon={IconLanguage} />\n<br />\n<br />\n<small>Alert</small> <br />\n<IconButton icon={IconLanguage} type=\"alert\" />\n<br />\n<br />\n<small>Disabled</small> <br />\n<IconButton icon={IconLanguage} disabled />\n<br />\n<br />\n<small>Action Icon</small> <br />\n<IconButton icon={IconLanguage} isActionIcon />\n<br />\n<br />\n<small>Dark Icon Button</small> <br />\n<IconButton type=\"dark\" icon={IconLanguage} />\n<br />\n<small>Dark Icon Button with menu</small> <br />\n<IconButton type=\"dark\" icon={IconLanguage} withMenu />\n<br />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Button,
      ButtonPrimary,
      ButtonSecondary,
      ButtonTertiary,
      IconButton,
      IconLanguage,
      IconArrowDropDown,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <small>Primary</small> <br />
    <IconButton icon={IconLanguage} mdxType="IconButton" />
    <br /><br />
    <small>Alert</small> <br />
     <IconButton icon={IconLanguage} type='alert' mdxType="IconButton" />
     <br /><br />
     <small>Disabled</small> <br />
    <IconButton icon={IconLanguage} disabled mdxType="IconButton" />
    <br /><br />
    <small>Action Icon</small> <br />
    <IconButton icon={IconLanguage} isActionIcon mdxType="IconButton" />
    <br /><br />
    <small>Dark Icon Button</small> <br />
    <IconButton type="dark" icon={IconLanguage} mdxType="IconButton" />
    <br />
    <small>Dark Icon Button with menu</small> <br />
    <IconButton type="dark" icon={IconLanguage} withMenu mdxType="IconButton" />
    <br />
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Button} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      